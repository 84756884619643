import styled from 'styled-components';

export const StyledBlog = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 20px;

  .blog-grid-item {
    box-sizing: border-box;
    margin: 20px 0;

    &__featured {
      padding-right: 20px;
      width: 50%;
      font-size: ${({ theme }) => theme.fontSizes.m};

      & article:first-of-type {
        font-size: ${({ theme }) => theme.fontSizes.l};
      }

      @media only screen and (max-width: ${({ theme }) => theme.breakpoints[0]}) {
        width: 70%;
      }

      @media only screen and (max-width: ${({ theme }) => theme.breakpoints[1]}) {
        width: 100%;
        padding-right: 0;
      }

      @media only screen and (max-width: ${({ theme }) => theme.breakpoints[3]}) {
        & article:not(:first-of-type) {
          font-size: ${({ theme }) => theme.fontSizes.s};
        }
      }

      @media only screen and (max-width: ${({ theme }) => theme.breakpoints[5]}) {
        & article:not(:first-of-type) {
          font-size: ${({ theme }) => theme.fontSizes.xs};
        }
      }
    }

    &__hot {
      padding-right: 20px;
      width: 20%;
      font-size: ${({ theme }) => theme.fontSizes.s};

      @media only screen and (max-width: ${({ theme }) => theme.breakpoints[0]}) {
        width: 30%;
        padding-right: 0px;
      }

      @media only screen and (max-width: ${({ theme }) => theme.breakpoints[1]}) {
        width: 100%;

        & article {
          flex-basis: 50%;
        }
      }

      @media only screen and (max-width: ${({ theme }) => theme.breakpoints[4]}) {
        font-size: ${({ theme }) => theme.fontSizes.m};

        & article {
          flex-basis: 100%;
        }
      }
    }

    &__side {
      border-left: 1px solid ${({ theme }) => theme.colors.gray};
      width: 30%;
      padding-left: 20px;
      margin-right: 0;
      font-size: ${({ theme }) => theme.fontSizes.m};

      & > *:last-of-type {
        width: 70%;
        font-size: ${({ theme }) => theme.fontSizes.s};
      }

      @media only screen and (max-width: ${({ theme }) => theme.breakpoints[0]}) {
        width: 100%;
        padding-left: 0;
        border-left: none;
        display: flex;
        flex-direction: row-reverse;

        & section {
          width: 50%;

          &:last-of-type {
            padding-right: 20px;
            & article {
              flex-basis: 50%;
            }
          }
        }
      }

      @media only screen and (max-width: ${({ theme }) => theme.breakpoints[2]}) {
        & section {
          &:first-of-type {
            width: 60%;
          }
          &:last-of-type {
            width: 40%;
            & article {
              flex-basis: 100%;
            }
          }
        }
      }

      @media only screen and (max-width: ${({ theme }) => theme.breakpoints[3]}) {
        & section {
          &:first-of-type {
            width: 50%;
            & article {
              flex-basis: 100%;
            }
          }
          &:last-of-type {
            width: 50%;
            & article {
              flex-basis: 100%;
              font-size: ${({ theme }) => theme.fontSizes.m};
            }
          }
        }
      }

      @media only screen and (max-width: ${({ theme }) => theme.breakpoints[4]}) {
        flex-direction: column;
        & section {
          &:first-of-type {
            width: 100%;
          }

          &:last-of-type {
            width: 100%;
          }
        }
      }
    }
  }
`;
