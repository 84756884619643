import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import { styles } from './styles';

ReactModal.setAppElement('#root');

const Modal = ({ children, isOpen, onAfterOpen, onRequestClose, contentLabel }) => {
  return (
    <ReactModal
      style={styles}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
    >
      {children}
    </ReactModal>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  onAfterOpen: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
  contentLabel: PropTypes.string
};

Modal.defaultProps = {
  content: 'Put Content here',
  onRequestClose: () => {}
};

export default Modal;
