import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import YouTube from 'react-youtube';

import { ALL_PUBLISHED_POSTS } from '../../graphql/queries';
import { getVideoId } from '../../common/helpers';
import Pagination from '../Pagination';
import PostsSection from '../PostsSection';
import Modal from '../Modal';
import { StyledBlog } from './blog.styled';

const Blog = ({ featuredPost, popularPosts, tycoonPosts, videoPosts, randomShuffledPosts }) => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [videoId, setVideoId] = useState('');

  const handleVideoOpen = videoUrl => {
    setVideoId(getVideoId(videoUrl));
    setIsVideoModalOpen(true);
  };

  const handleVideoClose = () => {
    setVideoId('');
    setIsVideoModalOpen(false);
  };

  return (
    <StyledBlog>
      <Modal
        isOpen={videoId && isVideoModalOpen}
        onRequestClose={handleVideoClose}
      >
        <YouTube
          id={videoId}
          videoId={videoId}
          opts={{
            playerVars: {
              autoplay: 1
            },
            minWidth: '300',
            maxWidth: '640'
          }}
        />
      </Modal>
      <div className="blog-grid-item blog-grid-item__featured">
        <PostsSection
          sectionTitle="Featured"
          posts={[featuredPost, ...randomShuffledPosts]}
        />
      </div>
      <div className="blog-grid-item blog-grid-item__hot">
        <PostsSection
          sectionTitle="Hot Posts"
          posts={popularPosts}
          postProps={{ direction: 'column' }}
        />
      </div>
      <div className="blog-grid-item blog-grid-item__side">
        <PostsSection
          sectionTitle="Videos"
          posts={videoPosts}
          postProps={{
            direction: 'column',
            type: 'videoLink',
            handleVideoOpen
          }}
        />
        <PostsSection
          sectionTitle="Tycoon News"
          posts={tycoonPosts}
          postProps={{ direction: 'column' }}
        />
      </div>
      <Query query={ALL_PUBLISHED_POSTS} variables={{ sortBy: 'publish_date', order: 'DESC', limit: 8, from: 0 }}>
        {
          ({ loading, error, data, refetch }) => {
            return (
              <Pagination
                title="Other News"
                posts={data ? data.allPublishedPosts : []}
                handlePagination={() => refetch({ sortBy: 'publish_date', order: 'DESC', limit: data.allPublishedPosts.length * 2, from: 0 })}
                className="blog-grid-item"
                loading={loading}
              />
            );
          }
        }
      </Query>
    </StyledBlog>
  );
};

Blog.propTypes = {
  featuredPost: PropTypes.object.isRequired,
  popularPosts: PropTypes.array.isRequired,
  tycoonPosts: PropTypes.array.isRequired,
  videoPosts: PropTypes.array.isRequired
};

Blog.defaultProps = {
  featuredPost: {},
  popularPosts: [],
  tycoonPosts: [],
  videoPosts: []
};

export default Blog;
